// Header on top
.header-top {
  position: fixed;
  width: 100%;
  display: flex;
  height: $header_height;
  background-color: #191a1c;
  color: $white;
  padding: 1rem 0;
  z-index: 1000;

  .header_logo {
    display: block;
    margin-left: 5%;

    & img {
      height: 3rem;
      background: transparent;
      cursor: pointer;
    }
  }

  .header_navigation {
    margin-right: 5%;
    margin-left: 10%;
    display: flex;
    padding-top: 0.5rem;
    width: 60%;
    .contact-header {
      height: 3rem;
      padding: 0rem 2rem;
      color: $white;
      font-size: 1.2rem;
      font-family: "Montserrat";
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      text-decoration: none;
    }
    & a {
      height: 3rem;
      padding: 0rem 2rem;
      color: $white;
      font-size: 1.2rem;
      font-family: "Montserrat";
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      text-decoration: none;
    }
  }

  .header-language {
    display: flex;
    .header-img-lg {
      .header-lg-button {
        cursor: pointer;
        border: none;
        background: none;
      }
    }
    .header-img-lg img {
      width: 40px;
    }
    .header-img-lg p {
      color: white;
    }
  }
}

// Header on scroll
.header {
  position: fixed;
  width: 100%;
  display: flex;
  height: 4rem;
  background: rgba(25, 26, 28, 0.8);
  color: $white;
  z-index: 1000;

  .header_logo {
    display: block;
    margin-left: 5%;

    & img {
      height: 2.5rem;
      padding: 1rem 0;
      background: transparent;
      cursor: pointer;
    }
  }

  .header_navigation {
    margin-right: 5%;
    display: flex;
    font-size: 18px;
    padding-top: 1rem;
    width: 70%;
    .contact-header {
      height: $header_height;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      color: $white;
      font-size: 18px;
      cursor: pointer;
      background-color: transparent;
      font-family: "Montserrat";
      border: none;
      outline: none;
      text-decoration: none;
    }
    & a {
      height: $header_height;
      padding: 1rem 2.5rem;
      color: $white;
      font-size: 18px;
      cursor: pointer;
      background-color: transparent;
      font-family: "Montserrat";
      border: none;
      outline: none;
      text-decoration: none;
    }
  }
  .header-language {
    display: flex;
    padding-top: 1vh;
    .header-img-lg {
      .header-lg-button {
        cursor: pointer;
        border: none;
        background: none;
      }
    }
    .header-img-lg img {
      width: 40px;
    }
  }
}

.header-top-mobile {
  display: none;
}

.header-mobile {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .header-top {
    .header_logo {
      margin-left: 5%;
    }

    .header_navigation {
      margin-right: 2%;

      & button {
        padding: 0 1.5rem;
      }

      .production-dropdown {
        padding-left: 22px;
        font-size: 1.15rem;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .header {
    .header_logo {
      margin-left: 5%;
    }

    .header_navigation {
      & button {
        padding: 1rem 2.5rem;
      }

      .production-dropdown {
        padding-left: 30px;
        font-size: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .header-top {
    .header_logo {
      margin-left: 2%;
    }

    .header_navigation {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .header-top {
    display: none;
  }

  .header {
    display: none;
  }

  .header-top-mobile {
    display: block;
    position: fixed;
    width: 90%;
    display: flex;
    justify-content: space-between;
    height: $header_height;
    background: rgba(0, 0, 0);
    color: $white;
    padding: 2rem 5%;
    z-index: 1000;

    & img {
      height: 3.5rem;
      background: transparent;
      cursor: pointer;
    }
  }

  .header-mobile {
    display: block;
    position: fixed;
    width: 90%;
    display: flex;
    justify-content: space-between;
    height: $header_height;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    padding: 1rem 5%;
    z-index: 1000;

    & img {
      height: 3rem;
      background: transparent;
      cursor: pointer;
    }
  }

  .no-padding {
    padding: 0;
  }

  .header_navigation-mobile {
    position: absolute;
    width: 100vw;
    height: 50vh;
    background: rgba(0, 0, 0, 0.95);
    color: $white;
    text-align: center;

    .header-navigation-content-mobile {
      padding-top: 10vh;

      div {
        width: 100%;
        .contact-header {
          font-size: 24px;
        }
        a {
          justify-content: center;
          background-color: transparent;
          color: $white;
          border: none;
          font-size: 24px;
          text-decoration: none;
        }
      }

      .production-dropdown-mobile {
        font-size: 18px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .header_navigation-mobile-close {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 36px;
      cursor: pointer;
    }
  }

  .header_navigation-hamburger {
    cursor: pointer;
    font-size: 3rem;
    margin-top: -1rem;
  }
}
