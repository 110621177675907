.products {
  width: 100%;
  padding-bottom: 20vh;
  color: $white;
  background-color: #191a1c;

  .products-title {
    padding-top: 10vh;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }

  .products-images {
    display: flex;
    width: 80%;
    margin-left: 10vw;
    padding-top: 4rem;
    text-align: left;
  }

  .products-images a {
    text-decoration: none;
    color: white;
  }

  .product-image {
    display: block;
    width: 33%;
    .product-centar {
      width: 80%;
      margin-left: 0%;
      .img-div {
        width: 100%;
        text-align: center;
      }
      & img {
        text-align: center;
        width: 20%;
      }
      .product-title {
        padding-top: 1rem;
        font-weight: bolder;
        font-size: 1.5rem;
        text-align: center;
      }
    }
    .product-subtitle {
      padding-top: 5vh;
      width: 90%;
      text-align: left;
      height: 150px;
      font-size: 1rem;
    }

    .product-button {
      padding-top: 4vh;
    }
    & a {
      background-color: #e4c22a;
      color: white;
      font-weight: bold;
      font-size: 1rem;
      padding: 10px 40px;
      border: #e4c22a;
      border-radius: 25px;
      justify-content: right;
      cursor: pointer;
    }
  }
  .product-footer {
    position: relative;
    padding-top: 5vh;
    width: 100%;
    text-align: center;
    padding-bottom: 4vh;
  }
}

@media only screen and (max-width: 1300px) {
  .products {
    height: auto;
    .products-images {
      padding-top: 0rem;
    }
    .product-image {
      padding-top: 4vh;
      .ison-products-img {
        width: 70%;
      }
      .product-title {
        font-size: 1rem;
      }

      .product-subtitle {
        height: 180px;
      }
    }
    .img-in-row {
      display: block;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .products {
    .product-image {
      .product-subtitle {
        height: 180px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .products {
    padding-bottom: 8vh;
    .products-images {
      margin-left: 5vw;
      width: 90%;
    }
    .products-title {
      padding-top: 5vh;
    }
  }
}

@media only screen and (max-width: 800px) {
  .products {
    .product-image {
      .product-subtitle {
        height: 210px;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .products {
    .product-image {
      .product-subtitle {
        height: 240px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .products {
    .products-images {
      display: block;
    }

    .product-image {
      width: 100%;
      padding-top: 3vh;
      text-align: left;
      .product-button {
        padding-top: 2vh;
        padding-bottom: 4vh;
      }
      .product-centar {
        width: 100%;
      }

      .product-subtitle {
        padding-top: 2vh;
        height: auto;
      }
    }
    .products-title {
      font-size: 2rem;
    }
  }
}
