.dashboard {
  width: 100%;
  height: 100vh;
  color: $white;
  display: block;
  .dashboard-video {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -1;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1); /* dark overlay color */
  }
  .dashboard-image {
    width: 20%;
    position: absolute;
    z-index: 900;
    margin-top: 10%;
    margin-left: 10%;
  }
  & img {
    width: 100%;
  }

  .dashboard-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 36%;
    margin-left: 32%;
    padding-top: 45vh;
    font-size: 1.5rem;
    font-weight: 100;
    z-index: 0;
    font-family: "Montserrat";
  }

  .dashboard-footer {
    position: absolute;
    bottom: 2vh;
    width: 90%;
    font-weight: bold;
    text-align: right;
  }
}

@media only screen and (max-width: 1100px) {
  .dashboard {
    .dashboard-content {
      width: 50%;
      margin-left: 25%;
    }
  }
}

@media only screen and (max-width: 850px) {
  .dashboard {
    .dashboard-content {
      width: 60%;
      margin-left: 20%;
    }
  }
}

@media only screen and (max-width: 650px) {
  .dashboard {
    .dashboard-content {
      width: 70%;
      margin-left: 15%;
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  .dashboard {
    .dashboard-content {
      width: 80%;
      margin-left: 10%;
    }
  }
}

@media only screen and (max-height: 600px) {
  .dashboard {
    .dashboard-content {
      padding-top: 70vh;
    }
  }
}
