.modal-close {
  position: absolute;
  color: white;
  font-size: 30px;
  width: 100%;
  text-align: end;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 1;

  & span {
    cursor: pointer;
  }
}

.simple-image-slider {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.rsis-container .rsis-image {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.rsis-container div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.rsis-image {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.image-slider {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  .rsis-container .rsis-image {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
  .rsis-container div {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

@media only screen and (max-width: 550px) {
  .modal-close {
    padding-top: 30px;
  }
}
