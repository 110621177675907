/* Colors */
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #f6f6f7;
$gray-300: #e2e7f1;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #4b4b5a;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$header-height: 2.5rem;
