.footer {
  width: 100%;
  height: auto;
  background-color: black;
  color: white;
  padding-top: 10vh;
  .footer-page {
    display: flex;
    .footer-logo-phone {
      display: none;
    }
    .footer-left {
      width: 50%;
      margin-left: 5%;
      .register-login {
        color: white;
        font-weight: lighter;
        padding-top: 1vh;
        font-size: 2rem;
      }

      .register-input {
        padding-top: 1.5vh;
        color: white;
        width: 90%;
      }
      & input {
        background-color: transparent;
        border: solid 0.5px white;
        padding-top: 8px;
        color: white;
        width: 100%;

        font-family: "Montserrat";

        padding-bottom: 8px;
        padding-left: 4px;
      }
      & input::placeholder {
        color: white;
      }

      .register-button {
        padding-top: 1.5vh;
        text-align: left;
      }
      & button {
        color: white;
        border: none;
        background: none;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .footer-content {
      position: relative;
      margin-right: 10%;
      text-align: right;
      width: 50%;
      .footer-logo {
        height: 3rem;
        text-align: right;
      }

      .footer-text {
        padding-top: 4vh;
      }
      .footer-email {
        display: none;
      }
      .footer-media {
        display: flex;
        width: 50%;
        margin-left: 50%;
        text-align: right;
        padding-top: 4vh;
        justify-content: space-between;
      }
    }
  }
  .footer-copyright {
    width: 100%;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}

@media only screen and (max-width: 1000px) {
  .footer {
    .footer-page {
      .footer-left {
        width: 55%;
      }
      .footer-content {
        width: 45%;
        .footer-media {
          margin-left: 40%;
          width: 60%;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .footer {
    padding-top: 5vh;

    .footer-page {
      .footer-logo-phone {
        display: block;
        width: 100%;
        padding-bottom: 1vh;
      }
      & img {
        text-align: center;
        width: 50%;
        margin-left: 5%;
      }
      display: block;
      .footer-left {
        width: 90%;
      }
      .footer-content {
        padding-top: 3vh;
        width: 90%;
        text-align: left;
        margin-left: 5%;
        .footer-logo {
          display: none;
        }
        .footer-media {
          margin-left: 5%;
          width: 90%;
        }
        .footer-text {
          display: none;
        }
        .footer-email {
          padding-top: 1vh;
          text-align: center;
          display: block;
        }
      }
    }
  }
}
