.about-us {
  width: 100%;
  color: $white;
  display: block;
  background-color: #191a1c;

  .about-us-all-page {
    display: block;
    height: auto;
    padding-top: 10vh;
    .about-us-baner-homepage {
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
    }
    .about-us-title-homepage {
      padding-top: 5vh;
      text-align: center;
    }
    & img {
      width: 40vw;
    }
    .about-us-baner {
      padding-top: 5vh;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
    }
    .about-us-title {
      padding-top: 5vh;
      text-align: center;
      font-weight: bold;
      font-size: 3rem;
    }
    .about-us-video-div {
      padding-top: 5vh;
    }
    .about-us-video {
      width: 80vw;
      margin-left: 10vw;
    }

    .partneri {
      width: 80vw;
      margin-left: 10vw;
      padding-top: 7vh;
      .partneri-title {
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
      }
    }

    .partneri .partneri-logovi {
      display: flex;
    }

    .partneri .partneri-logovi button {
      width: 20%;
      background: none;
      margin: 20px 0 20px 2.5%;
      border: 0;
      padding: 0;
      cursor: pointer;
    }

    .partneri .partneri-logovi button img {
      width: 70%;
      height: auto;
    }

    .partneri .partneri-logovi button:hover img {
      transform: scale(1.5);
      transition: all 1s ease;
    }
    .about-us-page {
      display: flex;
      padding-top: 5vh;
      .about-us-left {
        display: block;
        width: 40vw;

        .ison-about-us-left {
          position: relative;
          padding-left: 10%;
          height: auto;
          width: 70%;
        }
        & img {
          object-fit: cover;
        }
      }
      .about-us-right {
        display: block;
        width: 60vw;
        text-align: left;
        padding-top: 1vh;
        height: 74vh;
        .about-us-right-all-text {
          height: auto;
          font-size: 1rem;
          .about-us-right-title {
            font-size: 2rem;
            font-family: "Montserrat";
            font-weight: bold;
            padding-bottom: 5vh;
          }
          .about-us-right-text {
            width: 90%;
            padding-bottom: 2vh;
            font-family: "Montserrat";
            font-weight: lighter;
          }
          #about-us-right-text {
            font-weight: bold;
          }
        }
      }
    }
    .about-us-footer {
      width: 100%;
      position: relative;
      text-align: center;
      padding-top: 5vh;
      padding-bottom: 5vh;
    }
  }
}

@media only screen and (max-height: 700px) {
  .about-us {
    .about-us-all-page {
      height: auto;
      .about-us-page {
        padding-top: 5vh;
        padding-bottom: 5vh;
        .about-us-left {
          .ison-about-us-left {
            height: auto;
          }
        }
        .about-us-right {
          height: auto;
          .about-us-right-all-text {
            height: auto;
          }
          .about-us-right-box {
            height: auto;
            padding-top: 2vh;
            .about-us-right-text-box {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .about-us {
    .about-us-all-page {
      height: auto;
      padding-bottom: 5vh;
      .about-us-title {
        margin-left: 5%;
        width: 90%;
        font-size: 2.5rem;
      }
      .about-us-video {
        margin-left: 5vw;
        width: 90vw;
      }
      .about-us-baner {
        padding-top: 8vh;
        width: 90%;
        margin-left: 5%;
      }

      .partneri {
        margin-left: 5vw;
        width: 90vw;
      }
      .about-us-page {
        display: block;

        padding-top: 5vh;
        .about-us-left {
          width: 100%;
          .ison-about-us-left {
            padding-left: 25%;
            width: 50%;
            height: auto;
          }
        }

        .about-us-right {
          padding-left: 10%;
          width: 80%;
          height: auto;
          .about-us-right-all-text {
            height: auto;
            .about-us-right-title {
              padding-top: 1vh;
              font-size: 2rem;
            }
            .about-us-right-text {
              width: 100%;
              font-size: 1.2em;
            }
          }

          .about-us-right-box {
            display: block;
            padding-top: 5vh;
            width: 100%;
            height: auto;
            .about-us-right-img {
              width: 100%;
            }
            .about-us-right-text-box {
              padding-top: 2vh;
              width: 100%;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
    .about-us-footer {
      padding-top: 3vh;
    }
  }
}

@media only screen and (max-height: 450px) {
  .about-us {
    .about-us-all-page {
      padding-top: 20vh;
    }
  }
}

@media only screen and (max-height: 415px) {
  .about-us {
    .about-us-all-page {
      padding-top: 25vh;
    }
  }
}

@media only screen and (max-width: 450px) {
  .about-us {
    .about-us-all-page {
      .about-us-page {
        .about-us-left {
          .ison-about-us-left {
            padding-left: 15%;
            width: 70%;
          }
        }
      }
    }
  }
}
