.projects {
  width: 100%;
  color: $white;
  background-color: #191a1c;
  .projects-all-page {
    display: block;
    width: 100%;
    padding-top: 15vh;
    .projects-title {
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
    }
    .projects-menu {
      position: relative;
      display: flex;
      margin-left: 10%;
      justify-content: space-between;
      height: 5vh;
      padding-top: 5vh;
      color: white;
      width: 80%;
      height: auto;
    }
    & button {
      cursor: pointer;
      text-decoration: none;
      background: none;
      border: none;
      width: 50%;
      font-size: 1.5rem;
      color: white;
      .projects-tab {
        text-align: center;
      }
      #selected {
        font-weight: bold;
        border-bottom: 3px solid;
        border-color: #e4c22a;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .projects {
    .projects-all-page {
      padding-top: 20vh;
    }
  }
}

@media only screen and (max-height: 430px) {
  .projects {
    .projects-all-page {
      padding-top: 30vh;
    }
  }
}
