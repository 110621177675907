.contact {
  width: 100%;
  display: block;

  .contact-contains {
    display: flex;
    color: white;
    padding-top: 25vh;

    .contact-left {
      width: 50%;
      display: block;
      .contact-title {
        font-weight: bold;
        padding-left: 20vw;
      }
      .contact-subtitle {
        padding-left: 20vw;
        font-size: 2rem;
        font-weight: lighter;
      }
      .contact-mapa {
        display: flex;
        padding-top: 2rem;
        height: 30vh;
        .contact-mapa-img {
          width: 5vw;
          padding-left: 15vw;
        }
        .contact-mapa-text {
          padding-top: 1.5rem;
          display: block;
          .contact-mapa-text-title {
            font-family: "Montserrat-Bold";
          }
          .contact-mapa-text-subtitle {
            font-weight: lighter;
          }
        }
      }
    }
    .contact-right {
      width: 50%;
      display: block;
      .maps {
        width: 80%;
      }
    }
  }
}
@media only screen and (max-width: 912px) {
  .contact {
    background-color: #102951;

    .contact-contains {
      .contact-left {
        .contact-title {
          padding-left: 10vw;
        }
        .contact-subtitle {
          padding-left: 10vw;
        }
        .contact-mapa {
          .contact-mapa-img {
            padding-left: 5vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .contact {
    height: auto;
    .contact-contains {
      display: block;
      padding-top: 15vh;

      .contact-left {
        width: 90%;
        .contact-title {
          padding-left: 15vw;
          font-size: 0.8rem;
        }
        .contact-subtitle {
          padding-left: 15vw;
          font-size: 1rem;
        }
        .contact-mapa {
          padding-top: 0.5rem;
          .contact-mapa-img {
            padding-left: 5vw;
          }
          & img {
            width: 100%;
          }
          .contact-mapa-text {
            padding-top: 1rem;
            padding-left: 3vw;
            font-size: 0.8rem;
          }
        }
      }
      .contact-right {
        width: 90%;
        padding-top: 5vh;
        padding-left: 5%;
        .maps {
          width: 100%;
          height: 40vh;
        }
      }
    }
  }
}
@media only screen and (max-height: 550px) {
  .contact {
    background-color: #102951;
    .contact-contains {
      padding-top: 40vh;
      .contact-right {
        .maps {
          height: 60vh;
        }
      }
      .contact-left {
        padding-top: 0.5rem;
        .contact-title {
          padding-left: 8vw;
        }
        .contact-subtitle {
          padding-left: 8vw;
          font-size: 1.5rem;
        }
        .contact-mapa {
          padding-top: 0.5rem;
          .contact-mapa-img {
            padding-left: 2vw;
            width: 3vw;
          }
          & img {
            width: 100%;
          }
          .contact-mapa-text {
            padding-top: 1rem;
            padding-left: 3vw;
          }
        }
      }
    }
  }
}
