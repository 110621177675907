.film-details {
  width: 100%;
  background-color: #191a1c;
  color: $white;
  .film-details-all-page {
    display: block;
    width: 100%;
    padding-top: 0;

    .film-details-img {
      width: 100%;
      padding-top: 4rem;
    }
    & img {
      width: 100%;
      object-fit: cover;
    }

    .film-details-menu {
      position: relative;
      display: flex;
      margin-left: 10%;
      justify-content: space-between;
      height: 5vh;
      padding-top: 5vh;
      color: $white;
      width: 80%;
      height: auto;
      #selected {
        font-weight: bold;
        border-bottom: 3px solid;
        border-color: #e4c22a;
      }
    }
    & button {
      cursor: pointer;
      text-decoration: none;
      background: none;
      border: none;
      width: 50%;
      font-size: 1.5rem;
      color: $white;
      .film-details-tab {
        text-align: center;
      }
      #selected {
        font-weight: bold;
      }
    }
    .film-trailer {
      display: block;
      padding-bottom: 10vh;
      .film-details-title {
        font-weight: bold;
        font-size: 2rem;
        text-align: left;
        padding-top: 5vh;
        width: 40%;
        margin-left: 10%;
      }
      .film-details-video {
        padding-top: 6vh;
        width: 80%;
        margin-left: 10%;
        .film-video {
          width: 100%;
        }

        #tracak-video {
          height: 60vh;
        }
      }
      .img-mfp-trailer {
        width: 80%;
        margin-left: 10%;
        text-align: right;
        padding-top: 3vh;
      }
      & img {
        width: 30%;
      }

      .film-details-text {
        padding-top: 5vh;
        width: 40%;
        margin-left: 10%;
        font-size: 1.5rem;
        text-align: justify;
      }

      .row-img {
        display: flex;
        width: 80%;
        margin-left: 10%;
        padding-top: 5vh;
        justify-content: space-between;
        .film-img {
          width: 100%;
        }
        & img {
          cursor: pointer;
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .film-details {
    .film-details-all-page {
      .details-img-mfp {
        padding-top: 8vh;
      }
      .film-details-img {
        padding-top: 3vh;
      }
      #tracak-details-image {
        padding-top: 8vh;
      }
      .film-trailer {
        padding-top: 3vh;
        .film-details-title {
          font-size: 1.5rem;
          width: 80%;
        }
        .film-details-text {
          width: 80%;
          margin-left: 10%;
          font-size: 1rem;
          text-align: justify;
        }
        .row-img {
          display: block;
          width: 80%;
          margin-left: 10%;
          padding-top: 0%;
          .film-img {
            width: 100%;
            padding-bottom: 1vh;
          }
          & img {
            width: 100%;
          }
        }
      }
    }
  }
}
