.register {
  padding-top: 10vh;
  display: block;
  width: 100%;
  .register-page {
    width: 25%;
    padding-left: 20vw;

    .register-img img {
      height: 3.5rem;
    }
    .register-text {
      color: white;
      font-family: "Montserrat";
      padding-top: 5vh;
    }

    .register-login {
      color: white;
      font-weight: lighter;
      padding-top: 1vh;
      font-size: 2rem;
    }

    .register-input {
      padding-top: 1.5vh;
      color: white;
      width: 95%;
    }
    & input {
      background-color: transparent;
      border: solid 0.5px white;
      padding-top: 8px;
      color: white;
      width: 100%;

      font-family: "Montserrat";

      padding-bottom: 8px;
      padding-left: 4px;
    }
    & input::placeholder {
      color: white;
      font-family: "Montserrat";
    }

    .register-button {
      padding-top: 1.5vh;
      text-align: right;
    }
    & button {
      color: white;
      border: none;
      background: none;
      font-family: "Montserrat-Bold";
      font-weight: bold;
      cursor: pointer;
    }
  }
  .register-footer {
    width: 100%;
    text-align: center;
    padding-top: 10vh;
    padding-bottom: 5vh;
  }
}

@media only screen and (max-width: 912px) {
  .register {
    background-color: #102951;
  }
}

@media only screen and (max-width: 650px) {
  .register {
    .register-page {
      width: 80%;
      padding-left: 10vw;
      .register-img {
        text-align: center;
      }
      .register-text {
        font-size: 0.8rem;
      }
      .register-login {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (max-height: 550px) {
  .register {
    background-color: #102951;
    .register-page {
      width: 80%;
      padding-left: 10vw;
      .register-img {
        text-align: center;
      }
      .register-text {
        font-size: 0.8rem;
      }
      .register-login {
        font-size: 1.5rem;
      }
    }
  }
}
